import React, { useState } from "react";
import dayjs from 'dayjs';
import { Collapse } from 'antd';
import FormFields from "./FormFields.json";
import { TestSideBar } from "./SideBar";
import { TestRecovery } from "./Recovery";
import { TestReport } from "./Report";
import { EyeFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadOutlined, MailOutlined } from '@ant-design/icons';
import { LinkButton, Button, OvalLoading, Form, getModuleLink, DataTable, Modal, Menu, useExternalLink, useNavigateBack, OvalLoadingModal } from "../../../components";
import {
  commonActions, continuityActions, getCurrentUser, getCurrentViewTest, getStatusTypeById, getTestStatusTypes,
  encyptDataObject, isOrganizer, isSuperUser, getDepartments, getTaskProrityTypes, getDateInFormat,
  getDueDateByPriority, isAccessor, isTestResponder, getActiveUsers, isTestAdvisor, isTestInReview, isTestClosed,
  isTestOpen, isTestInProgress, isTestAbandoned, isTestRedundant, getTestReport, getReportPassword,
  isAuditee
} from "../../../store";

const MenuItems = [
  { dialogId: 'download', label: "Export (as Encrypted PDF)", className: '' },
  { dialogId: 'share', label: "Share (Encrypted PDF by Email)", className: '' }
]

const TaskColumns = [
  { title: 'Task ID', dataIndex: 'task_id', key: 'task_id', width: '13%', className: 'exo2 f14 no-wrap', render: 'link', variant: 'lite', color: '#0033CC', cellClassName: 'f12' },
  { title: 'Priority', dataIndex: 'priority', key: 'priority', width: '13%', className: 'exo2 f14 no-wrap', render: 'color-code', cellClassName: 'f12' },
  { title: 'Task Name', dataIndex: 'name', key: 'name', width: '23%', className: 'exo2 f14', cellClassName: 'f12 truncate-ellipsis' },
  { title: 'Responsible', dataIndex: 'responsible', key: 'responsible', width: '17%', className: 'exo2 f14 no-wrap', cellClassName: 'f12' },
  { title: 'Department', dataIndex: 'department', key: 'department', width: '17%', className: 'exo2 f14', cellClassName: 'f12' },
  { title: 'Status', dataIndex: 'task-status', key: 'task-status', width: '12%', className: 'exo2 f14 no-wrap', render: 'color-text', cellClassName: 'f12' },
]

const CreateTaskModal = (props) => {
  const dispatch = useDispatch();
  const _formRef = React.useRef(null);
  const { open, onClose, test } = props;
  const [task, setTask] = React.useState({});
  const departments = useSelector(getDepartments);
  const priorities = useSelector(getTaskProrityTypes);
  React.useEffect(() => {
    let task = {};
    if (open && test) {
      task.source = test.title;
    }
    setTask(task);
  }, [open]);
  const getFormFields = () => {
    const fields = FormFields.Task.map((_) => ({ ..._ }));
    fields[2].minDate = getDateInFormat();
    return fields;
  }
  const getOptions = (attribute) => {
    let options = [];
    switch (attribute) {
      case 'dept_name': options = departments; break;
      case 'priority':
        options = priorities;
        options = [];
        priorities.forEach((_) => {
          if (String(_.id).toLowerCase() !== 'none') {
            options.push({ ..._, className: `priority ${String(_.id).toLowerCase()}` })
          }
        })
        break;
    }
    return options;
  }
  const handleCreateTask = (e) => {
    e.preventDefault && e.preventDefault();
    if (_formRef.current && _formRef.current.validate) {
      let valid = _formRef.current.validate(e, true);
      if (!valid) {
        return
      }
    }
    const _task = {}
    FormFields.Task.forEach(({ attribute, type }) => {
      let value = task[attribute] || '';
      if (type === 'select' && value) {
        value = value.id ? value.id : value;
      }
      _task[attribute] = value;
    });
    let payload = { task: _task, continuer_id: test.id };
    dispatch(continuityActions.createTestTask(payload));
    props.onClose && props.onClose();
  }
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const _task = { ...task };
    if (name === 'priority' && value) {
      let offset = getDueDateByPriority(value.id);
      if (offset) {
        offset = dayjs().add(offset, "day").format();
        _task.due_date = offset;
      }
    }
    _task[name] = value;
    setTask(_task)
  };
  return (
    <Modal
      open={open}
      width={'80vw'}
      className='create-task'
      actions={[
        { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC', onClick: onClose },
        { label: 'Create', onClick: handleCreateTask },
      ]}
      title={<span className="exo2 f24 med c238787">Create New Task</span>} >
      {
        open &&
        <Form
          showColon
          ref={_formRef}
          className="row fwarp create-task-form"
          formData={task}
          Fields={getFormFields()}
          getOptions={getOptions}
          onChange={handleFieldChange}
          onSubmit={handleCreateTask} />
      }
    </Modal>
  )
}
const SendReviewModal = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, test } = props;
  const activeUsers = useSelector(getActiveUsers);
  const user = useSelector(getCurrentUser);
  const [reviewer, setReviewer] = React.useState(null);

  const handleSendReview = (e) => {
    e.preventDefault();
    let payload = new FormData();
    payload.append(`continuer[status]`, "Review");
    payload.append(`continuer[reviewer_id]`, Number(reviewer.id));
    payload.append('continuer_id', test.id);
    dispatch(continuityActions.updateTest(payload));
    onClose && onClose()
  }
  const handleFieldChange = (e) => {
    setReviewer(e.target.value)
  }
  const getOptions = () => {
    return activeUsers.filter(({ id }) => {
      return id !== user.id && test.reviewer_id !== id
    }).map(({ id, firstname, lastname, designation, department_name }) => {
      let fullName = `${firstname} ${lastname}, ${designation}, ${department_name}`
      return { id, label: fullName }
    })
  }
  return (
    <Modal
      open={open}
      width={'70vw'}
      className='send-review'
      actions={[
        { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC', onClick: onClose },
        { label: 'Send', onClick: handleSendReview, disabled: !reviewer },
      ]}
      title={<span className="exo2 f24 med c238787">Send for Review?</span>} >
      {
        open &&
        <Form
          showColon
          className="col"
          formData={{ reviewer_id: reviewer }}
          Fields={FormFields.Review}
          getOptions={getOptions}
          onChange={handleFieldChange}
          onSubmit={handleSendReview}
        />
      }
    </Modal>
  )
}

const TestStatusConfirmModal = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { open, onClose, test, status, isCoordinator } = props;
  const [state, setState] = useState({ justify: '', showRequestSent: false });
  const isReopening = (isTestClosed(test) || isTestRedundant(test) || isTestAbandoned(test)) && status && isTestInReview({ status: status.id })
  React.useEffect(() => {
    if (!open) {
      setState({ justify: '', showRequestSent: false })
    }
  }, [open])
  const handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    let payload = new FormData(), updateStatus, justification;
    let showRequestSent = false, addComment = false;
    if (isReopening || isTestRedundant({ status: status.id })) {
      if (isSuperUser(currentUser)) {
        updateStatus = status.id;
        justification = test.justification || state.justify;
        addComment = true;
      } else {
        justification = state.justify;
        showRequestSent = true;
      }
    } else {
      updateStatus = status.id;
    }
    if (updateStatus) {
      payload.append(`continuer[status]`, updateStatus);
    }
    if (justification) {
      payload.append('continuer[justification]', justification)
    }
    if (addComment) {
      payload.append('addComment', addComment)
    }
    payload.append('continuer_id', test.id);
    if (showRequestSent) {
      payload.append('request_status_change', status.id);
      setState((_) => ({ ..._, showRequestSent }))
    } else {
      onClose && onClose(isTestClosed(e, { status: status.id }));
    }
    dispatch(continuityActions.updateTest(payload));
  }
  const getText = (attr) => {
    let text = { title: '', desc: '' }, temp;
    if (status) {
      temp = { status: status.id };
      if (isReopening) {
        if (isSuperUser(currentUser)) {
          text.title = 'Confirm Review?';
          text.desc = "Are you sure, you want to proceed further?"
          if (!test.justification) {
            text.desc += "<br/>Provide a justification for this update."
          }
        } else {
          text.title = 'Request Re-Reviewing?';
          text.desc = 'This test will be marked as "Review".<br/>Provide a justification for this request.<br/>This justification will be sent for review and approval.'
        }
      } else if (isTestClosed(temp)) {
        text.title = 'Confirm Closure?';
        text.desc = 'Changing the Status of this Test to "Closed" will prevent any further changes.<br />Continuity Test Report will be generated.'
      } else if (isTestAbandoned(temp)) {
        text.title = 'Confirm Abandoned?';
        text.desc = 'Changing the Status of this Test to "Abandoned" will prevent any further changes.'
      } else if (isTestRedundant(temp)) {
        if (isSuperUser(currentUser)) {
          text.title = 'Confirm Redundancy?';
          text.desc = "Are you sure, you want to proceed further?"
          if (!test.justification) {
            text.desc += "<br/>Provide a justification for this update."
          }
        } else {
          text.title = 'Request Redundancy?';
          text.desc = 'This test will be marked as "Redundant".<br/>Provide a justification for this request.<br/>This justification will be sent for review and approval.'
        }
      } else if (isTestInReview(temp)) {
        text.title = 'Confirm Review?';
        text.desc = 'Are you sure you want to change the status of this Test to "Review"?'
      }

    }
    return text[attr];
  }
  const isJustificationEnabled = () => {
    return (!isSuperUser(currentUser) && isCoordinator) || (isSuperUser(currentUser) && !test.justification)
  }
  const getFormFields = () => {
    return FormFields.Redundant.map((_) => {
      let required = isJustificationEnabled();
      return { ..._, required: required, readOnly: !required }
    })
  }
  const getFormData = () => {
    return { justification: isJustificationEnabled() ? state.justify : test.justification };
  }
  const isConfirmDisabled = () => {
    return (Boolean(status) && (isTestRedundant({ status: status.id }) && (isJustificationEnabled() ? state.justify.length === 0 : false)))
  }
  return (
    <>
      <Modal
        open={open}
        width={527}
        actions={[
          {
            label: 'Confirm', variant: 'lite', className: 'min gap',
            color: '#0133CC', onClick: handleSubmit,
            disabled: isConfirmDisabled()
          },
          { label: 'Cancel', onClick: onClose },
        ]}
        title={<span className="exo2 f20 med c238787">{getText('title')}</span>}>
        <p className="f14 reg 00085" dangerouslySetInnerHTML={{ __html: getText('desc') }}></p>
        {
          Boolean(status) && (isTestRedundant({ status: status.id }) || isReopening) &&
          <Form
            showColon
            className="col"
            direction='vertical'
            formData={getFormData()}
            onChange={(e) => setState((_) => ({ ..._, justify: e.target.value }))}
            Fields={getFormFields()} />
        }
      </Modal>
      {
        state.showRequestSent &&
        <Modal
          open={state.showRequestSent}
          width={550}
          actions={[
            {
              label: 'Okay', color: '#0133CC', onClick: onClose,
            }
          ]}
          title={<span className="exo2 f20 med c238787">Request Submitted!</span>}>
          <p className="f14 reg 00085">Request is submitted to the Organiser to change the Test Status to {status.label}. <br />You will be notified when Organiser approves the request.</p>
        </Modal>
      }
    </>
  )
}
const ShareConfirmModal = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { open, onClose, test, shareInfo, onComplete } = props;
  const getEmails = () => {
    const emailIds = shareInfo.emailIds;
    const orgEmailDomains = currentUser.ent_org.email_domain.split(",").map((domain) => {
      return domain[0] === '@' ? domain.replace("@", '') : domain;
    })
    return emailIds.split(",").map((email) => {
      const emailArray = email.split("@");
      const external = orgEmailDomains.indexOf(emailArray[1]) === -1;
      return { emailArray, external }
    })
  }
  const handleSend = () => {
    dispatch(continuityActions.shareTestReport(shareInfo))
    onComplete && onComplete()
  }
  return (
    <Modal
      open={open}
      width={517}
      actions={[
        { label: 'Send', variant: 'lite', color: '#0133CC', onClick: handleSend },
        { label: 'Cancel', className: 'min gap', color: '#0133CC', onClick: onClose },
      ]}
      className='share-confirm'
      title={<span className="exo2 f20 bold c238787">Confirm Sharing By Email!</span>}>
      {
        open &&
        <p className="f14 reg 00085">Are you sure that you want to send the following Test Report with email <br /> addresses provided by you?<br />
          <span>Test ID : <span className="bold">{test.test_id}</span></span><br />
          <span>Test Title : {test.title}</span> <br /><br />
          <span>Email Address(s)</span>
          <ol className="emails">
            {
              getEmails().map((_) => {
                return (
                  <li key={_.email}>
                    <span className={`${_.external ? 'cc0000' : ''}`}>{_.emailArray[0]}@<span className={`${_.external ? 'bold' : ''}`}>{_.emailArray[1]}</span></span>
                  </li>
                )
              })
            }
          </ol>
        </p>
      }
    </Modal>
  )
}
const ExportTestModal = (props) => {
  const dispatch = useDispatch();
  const _formRef = React.useRef(null);
  const currentUser = useSelector(getCurrentUser);
  const { open, onClose, test, type } = props;
  const [state, setState] = useState({ shareInfo: null })
  const initialform = { pwd: getReportPassword(currentUser.ent_org), email: '', passCopied: false }
  const [form, setForm] = React.useState(JSON.parse(JSON.stringify(initialform)))
  const isDownload = type === MenuItems[0].dialogId
  React.useEffect(() => {
    if (!open) {
      setForm(JSON.parse(JSON.stringify(initialform)))
    }
  }, [open])
  const handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    const body = {
      password: `${form.pwd.suffix}${form.pwd.pass}`,
      testId: test.id,
      filename: `${test.test_id} - ${test.title}.pdf`
    }
    if (isDownload) {
      dispatch(continuityActions.downloadTestReport(body))
      onClose && onClose();
    } else {
      body.emailIds = form.email;
      handleShareConfirm(body)
    }
  }
  const handleShareConfirm = (body, close) => {
    setState((_) => ({ ..._, shareInfo: body }))
    if (close) {
      onClose && onClose()
    }
  }
  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    const _form = { ...form };
    if (name === 'pwd') {
      value = { ..._form[name], pass: value };
      _form.passCopied = false;
    }
    _form[name] = value;
    setForm(_form)
  }
  const isdisabled = () => {
    let disabled = Boolean(form.pwd.pass.length === 0 || (isDownload && !form.passCopied));
    if (!disabled && !isDownload) {
      disabled = form.email.length === 0;
    }
    return disabled
  }
  const handleCopyToClipboard = (isCopied) => {
    const _form = { ...form };
    _form.passCopied = isCopied;
    setForm(_form);
  }
  return (
    <React.Fragment>
      <Modal
        open={open}
        width={517}
        actions={[
          { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC', onClick: onClose },
          { label: isDownload ? 'Download' : 'Share', onClick: handleSubmit, disabled: isdisabled(), iconColor: 'cFFF', Icon: isDownload ? DownloadOutlined : MailOutlined },
        ]}
        className=''
        title={<span className="exo2 f20 bold c238787">{isDownload ? 'Download' : 'Share'} Continuity Test Report {test.test_id}</span>}>
        {
          open &&
          <Form
            showColon
            ref={_formRef}
            className="col export-form"
            formData={{ ...form }}
            Fields={isDownload ? FormFields.Download : FormFields.Share}
            onChange={handleFieldChange}
            onCopyToClipboard={handleCopyToClipboard}
            onSubmit={handleSubmit} />
        }
      </Modal>
      <ShareConfirmModal
        open={Boolean(state.shareInfo)}
        shareInfo={state.shareInfo}
        test={test}
        onComplete={handleShareConfirm.bind(null, null, true)}
        onClose={handleShareConfirm.bind(null, null, false)}
      />
    </React.Fragment>
  )
}
export const ViewTestScreen = (props) => {
  const { testId } = useParams()
  const external = useExternalLink()
  const _editors = React.useRef({});
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const goBack = useNavigateBack();
  const statusList = useSelector(getTestStatusTypes);
  const currentUser = useSelector(getCurrentUser);
  const test = useSelector(getCurrentViewTest);
  const report = getTestReport(test);
  const isOrganizerUser = isOrganizer(currentUser);
  const isCoordinator = isAccessor(currentUser) || isTestAdvisor(test, currentUser);
  const isResponder = isTestResponder(test, currentUser);
  const [state, setState] = React.useState({
    show: false, updatedTest: {}, showCreateTask: false, menuTarget: null, tasks: null,
    allowViewTasks: false, showReview: false, showClosure: false, showCreatedOwnView: false,
    currentTestStatus: '', showWaitForReport: false
  })
  const [activeKey, setActiveKey] = useState(['details']); // Initially expanded

  const handlePanelChange = (keys) => {
    setActiveKey(keys);
  };
  React.useEffect(() => {
    if (testId) {
      dispatch(continuityActions.fetchTest({ testId }))
      dispatch(commonActions.fetchTaskPriorities())
      dispatch(continuityActions.fetchStatusList())
    }
    return () => {
      external.unlink();
    }
  }, [])
  React.useEffect(() => {
    if (state.show) {
      external.init('.reference')
      const links = external.getAllLinks();
      Array.prototype.forEach.call(links, (link) => {
        link.classList.add("icon-ref-link")
      })
    }
  }, [state.show]);
  React.useEffect(() => {
    if (test) {
      setState((_) => ({ ..._, currentTestStatus: test.status }))
      if (test === 'NOTFOUND') {
        return navigate("/page-not-found")
      }
      if (!state.show) {
        if (
          (isTestClosed(test) && isAuditee(currentUser)) ||
          (!isResponder && !isTestAdvisor(test, currentUser) && !isSuperUser(currentUser))
        ) {
          return navigate('/page-not-found');
        }
        setState((_) => ({ ..._, show: true }))
      }
      if (isTestClosed(test) && Boolean(report)) {
        setState((_) => ({ ..._, showWaitForReport: false }))
      }
      createTaskLists();
    }
  }, [test])
  const getFormFields = (formID) => {
    let fields = [],
      fieldCompare = (_) => {
        let field = { ..._, config: { editorplaceholder: '' } };
        if (isTestAbandoned(test) || isTestRedundant(test)) {
          field.readOnly = true;
        } else if (isTestInReview(test)) {
          field.readOnly = !isCoordinator;
        }
        return field;
      }
    if (formID === 'header') {
      if (isTestClosed(test)) {
        fields = FormFields.Zoom;
        if (isOrganizerUser || isCoordinator) {
          fields = fields.concat(FormFields.Header)
        }
      } else {
        fields = FormFields.Header;
      }
      fields = fields.map((_) => {
        let field = { ..._ };
        if (field.attribute === 'status' && isTestInReview(test) && !isCoordinator) {
          field.readOnly = true;
        }
        return field;
      })
    } else if (formID === "details") {
      fields = FormFields.Details.map(fieldCompare)
    } else if (formID === "closure") {
      fields = FormFields.Closure.map(fieldCompare)
    }
    return fields
  }
  const getFormData = () => {
    let formData = { ...state.updatedTest };
    if (Boolean(test)) {
      let status = getStatusTypeById(statusList, test.status);
      formData.status = Boolean(status) ? { ...status, className: `status ${String(status.id).toLowerCase()}` } : '';
      [...FormFields.Details, ...FormFields.Closure].forEach(({ attribute, readOnly }) => {
        if (!formData[attribute]) {
          formData[attribute] = test[attribute]
        }
      });
    }
    return formData;
  }
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case 'status':
        options = [];
        for (let i = 0; i < statusList.length; i++) {
          let { id } = statusList[i]
          id = String(id).toLowerCase();
          const temp = { status: id }
          if (
            (!isTestOpen(test) && isTestOpen(temp)) ||
            (!isTestInProgress(test) && !isTestOpen(test) && isTestInProgress(temp)) ||
            (isTestClosed(test) && !isTestInReview(temp)) ||
            ((isTestAbandoned(test) || isTestRedundant(test)) && !isTestInReview(temp))
          ) {
            continue;
          }
          options.push({
            ...statusList[i],
            className: `status ${id}`
          })
        }
        break;
      default: options = []; break;
    }
    return options
  }
  const handleHTMLEditor = (name, e) => {
    if (e.editor) {
      _editors[name] = e.editor;
      _editors[name].document.getBody().setStyle('text-align', 'justify');
    }
  }
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const updateTest = { ...state.updatedTest }
    if (name === 'status' && value) {
      const tempTest = { status: value.id }
      if (
        ((isTestRedundant(test) || isTestAbandoned(test) || isTestClosed(test)) && isTestInReview(tempTest)) ||
        (isTestRedundant(tempTest) || isTestAbandoned(tempTest) || isTestClosed(tempTest))
      ) {
        return setState((_) => ({ ..._, showStatusConfirm: value }));
      }
      else if (isTestInReview(tempTest)) {
        return setState((_) => ({ ..._, showReview: true }));
      } else {
        updateTest[name] = value;
        return handleSubmitClosure(e, updateTest);
      }
    }
    updateTest[name] = value;
    setState((_) => ({ ..._, updateTest }));
  }
  const handleSubmitClosure = (e, iUpdatedTest) => {
    e.preventDefault && e.preventDefault();
    let payload = new FormData(), shouldUpdate = false;
    const updatedTest = { ...state.updatedTest, ...(iUpdatedTest || {}) };
    [...getFormFields("details"), ...getFormFields("header"), ...getFormFields("closure")].forEach(({ type, attribute, readOnly }) => {
      if (!readOnly) {
        let value = updatedTest[attribute]
        if (attribute === 'status' && test.status === 'open') {
          value = 'Inprogress';
        }
        if (type === 'select' && value) {
          value = value.id ? value.id : value
        } else if (type === "html" && _editors[attribute]) {
          value = _editors[attribute].getData();
        }
        if (value) {
          shouldUpdate = true;
          payload.append(`continuer[${attribute}]`, value);
        }
      }
    })
    if (shouldUpdate) {
      const status = payload.get('continuer[status]');
      if (isTestClosed({ status })) {
        setState((_) => ({ ..._, showWaitForReport: true }))
      }
      payload.append('continuer_id', test.id);
      dispatch(continuityActions.updateTest(payload));
    }
  }
  const handle3DotMenu = (e) => {
    setState((_) => ({ ..._, menuTarget: e.currentTarget }))
  }
  const handleMenuItemClick = (e, menu) => {
    setState((_) => ({ ..._, menuTarget: null, exportDialogType: menu ? menu.dialogId : null }))
  }
  const createTaskLists = () => {
    let rows = [], allowViewTasks = false;
    if (Array.isArray(test.tasks)) {
      rows = test.tasks.map((_) => {
        let task = { ..._ };
        if (checkTaskAccess(task)) {
          task.link = getTaskLink(task.guid);
          task.linkTarget = '_blank';
          allowViewTasks = true
        } else {
          task.unlink = true;
        }
        return { ...task }
      })
    }
    setState((_) => ({ ..._, tasks: rows, allowViewTasks }));
  }
  const handleTaskModal = (value) => {
    setState((_) => ({ ..._, showCreateTask: value }))
  }
  const checkTaskAccess = (task) => {
    let access = true;
    if (isTestAbandoned(test) || isTestRedundant(test)) {
      access = false;
    } else {
      access = isSuperUser(currentUser) ||
        (currentUser.id === task.assignee_id || currentUser.id === task.supervisor_id)
    }
    return access;
  }
  const getTaskLink = (taskGUID) => {
    let arclink = getModuleLink('arc', currentUser), redirect = { path: "/T" };
    // arclink = `http://localhost:3001/login?sessionToken=${currentUser.session_token}`; 
    if (taskGUID) {
      redirect.path += "/" + taskGUID  
    } else {
      redirect.search = `?continuity=${test.guid}` 
    }
    redirect = encyptDataObject(redirect)
    return `${arclink}&redirect=${redirect}`
  }
  const handleGoBack = (e) => {
    e.preventDefault();
    goBack();
  }
  const canShowSubmit = (formId) => {
    if (isTestAbandoned(test) || isTestRedundant(test)) {
      return false
    }
    let fields = getFormFields(formId).filter((_) => !_.readOnly)
    return fields.length > 0;
  }
  return (
    <div className="col w-100 h-100 o-hide view-test">
      {
        state.show ?
          <React.Fragment>
            <div className="col w-100 header">
              <LinkButton variant='lite' className='f14 reg btn-back' color='#0133CC' link='/' label='Tracker /' onClick={handleGoBack} />
              <div className="row w-100 h-btn">
                <span className="f20 exo2 c238787 bold">{test.test_id}: {test.title}</span>
                <div className="row fields">
                  <Form
                    showColon
                    className="row"
                    formData={getFormData()}
                    getOptions={getOptions}
                    onChange={handleFieldChange}
                    Fields={getFormFields('header')} />
                  {
                    (isTestClosed(test)) &&
                    <Button className='col v-ctr h-ctr btn-menu f8' icon="icon-dot c00085" variant='lite' onClick={handle3DotMenu} />
                  }
                </div>
              </div>
              {
                !isTestClosed(test) &&
                <div className="f16 col w-100 scenario txt-justify c00085 oy-auto" dangerouslySetInnerHTML={{ __html: test.scenario }} />
              }
            </div>
            {
              isTestClosed(test) ?
                <TestReport
                  zoom={state.updatedTest.zoom && state.updatedTest.zoom.id}
                  report={report} />
                :
                <React.Fragment>
                  <div className="col f-rest o-hide container">
                    <div className="row v-start w-100 h-100 o-hide">
                      <div className="col f-rest h-100 o-hide">
                        <div className="col h-100 w-100 toggle-sec oy-auto" >
                          {
                            isTestInReview(test) &&
                            <Collapse defaultActiveKey={['closure']} rootClassName="collapse">
                              <Collapse.Panel key='closure' className="panel" header={<span className="f16 exo2 reg 00085 caps">Closure</span>} >
                                <div className="col w-100">
                                  <Form
                                    showColon
                                    className="col closure-form"
                                    formData={getFormData()}
                                    onChange={handleFieldChange}
                                    onSubmit={handleSubmitClosure}
                                    onHtmlEditorReady={handleHTMLEditor}
                                    Fields={getFormFields('closure')} />
                                  {
                                    canShowSubmit('closure') &&
                                    <div className="row h-end">
                                      <Button label='Submit' onClick={handleSubmitClosure} />
                                    </div>
                                  }
                                </div>
                              </Collapse.Panel>
                            </Collapse>
                          }
                          <Collapse activeKey={activeKey} onChange={handlePanelChange} rootClassName="collapse">
                            <Collapse.Panel key='details' className="panel" header={<span className="f16 exo2 reg 00085">Details</span>} >
                              <div className="col w-100">
                                <Form
                                  showColon
                                  className="col details-form"
                                  formData={getFormData()}
                                  onChange={handleFieldChange}
                                  onSubmit={handleSubmitClosure}
                                  onHtmlEditorReady={handleHTMLEditor}
                                  Fields={getFormFields('details')} />
                                {
                                  canShowSubmit('details') &&
                                  <div className="row h-end">
                                    <Button label='Submit' onClick={handleSubmitClosure} />
                                  </div>
                                }
                              </div>
                            </Collapse.Panel>
                          </Collapse>
                          <Collapse defaultActiveKey={['task']} rootClassName="collapse">
                            <Collapse.Panel key='task' className="panel task-view" header={<span className="f16 exo2 reg 00085">Task</span>} >
                              {
                                !(isTestAbandoned(test) || isTestRedundant(test)) &&
                                <div className="row h-end">
                                  <LinkButton
                                    label="View Tasks"
                                    target="_blank"
                                    Icon={EyeFilled}
                                    iconColor='cFFF'
                                    disabled={!state.allowViewTasks}
                                    href={state.allowViewTasks ? getTaskLink() : '#'}
                                    onClick={(e) => {
                                      if (!state.allowViewTasks) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <Button
                                    label='Create Task'
                                    className='create-task-btn'
                                    icon='icon-plus-lite'
                                    iconColor='cFFF'
                                    onClick={handleTaskModal.bind(null, true)} />
                                </div>
                              }
                              <div className="col w-100">
                                <DataTable
                                  noSelection
                                  emptyMessage="There are no tasks right now!"
                                  Columns={TaskColumns}
                                  rows={state.tasks}
                                />
                              </div>
                            </Collapse.Panel>
                          </Collapse>

                        </div>
                      </div>
                      <TestRecovery test={test} />
                    </div>
                  </div>
                  <TestSideBar test={test} />
                </React.Fragment>
            }
            <CreateTaskModal
              test={test}
              open={state.showCreateTask}
              onClose={handleTaskModal.bind(null, false)}
            />
            <SendReviewModal
              test={test}
              open={state.showReview}
              onClose={() => {
                setState((_) => ({ ..._, showReview: false }))
              }}
            />
            <TestStatusConfirmModal
              test={test}
              open={Boolean(state.showStatusConfirm)}
              status={state.showStatusConfirm}
              isCoordinator={isCoordinator}
              isOrganizer={isOrganizerUser}
              onClose={(e, isClosed) => {
                setState((_) => ({
                  ..._,
                  showStatusConfirm: false,
                  showWaitForReport: isClosed || false
                }))
              }}
            />
            <ExportTestModal
              test={test}
              type={state.exportDialogType}
              open={Boolean(state.exportDialogType)}
              onClose={handleMenuItemClick}
            />
            <Menu
              anchorEl={state.menuTarget}
              onMenuClick={handleMenuItemClick}
              onClose={() => handle3DotMenu({ currentTarget: null })}
              menuItems={MenuItems.map((_) => ({ ..._, disabled: !Boolean(report) }))}
            />
            <OvalLoadingModal
              open={state.showWaitForReport}
              messageClassName="f16"
              message="Continuity Test Report is being generated. Please wait..."
            />
          </React.Fragment>
          :
          <OvalLoading />
      }
    </div>
  )
}